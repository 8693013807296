import { Container } from "@suraasa/placebo-ui"
import DeclarationCard from "components/application/DeclarationCard"
import DisabilityFitnessCard from "components/application/DisabilityFitnessCard"
import EducationCard from "components/application/EducationCard"
import EmploymentCard from "components/application/EmploymentCard"
import FloatingBar from "components/application/FloatingBar"
import LanguageCard from "components/application/LanguageCard"
import OthersCard from "components/application/OthersCard"
import PersonalDetails from "components/application/PersonalDetails"
import ReferencesCard from "components/application/ReferencesCard"
import TitleBar from "components/application/TitleBar"
import { GlobalContext } from "components/GlobalState"
import LoadingOverlay from "components/shared/LoadingOverlay"
import Navbar from "components/shared/Navbar/Navbar"
import { useContext, useState } from "react"
import { useParams } from "react-router-dom"
import { ACTIONS } from "utils/action"

import { ApplicationContextProvider } from "./context/ApplicationContext"

const Review = () => {
  const { applicationId } = useParams()
  const { actions } = useContext(GlobalContext)
  const isSalesPerson = !actions.includes(ACTIONS.assignApplication)
  const [pageLoading, setPageLoading] = useState(false)

  const togglePageLoading = (state: boolean) => {
    setPageLoading(state)
  }

  if (!applicationId) return null

  return (
    <ApplicationContextProvider
      applicationId={applicationId}
      isSalesPerson={isSalesPerson}
      toggleLoading={togglePageLoading}
    >
      <>
        <Navbar gutterBottom={false} />
        <TitleBar />
        {pageLoading && <LoadingOverlay />}
        {!pageLoading && (
          <Container className="mb-20 mt-3">
            <PersonalDetails />
            <EmploymentCard />
            <LanguageCard />
            <EducationCard />
            <DisabilityFitnessCard />
            <ReferencesCard />
            <OthersCard />
            <DeclarationCard />
            <FloatingBar />
          </Container>
        )}
      </>
    </ApplicationContextProvider>
  )
}

export default Review
